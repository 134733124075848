
import React, {
   useRef, useMemo, useState, 
} from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './index.scss';


const QuillInlineEditorSimple = ({
   text, onChange, isTemplate, modules, isOtherPage, isEmail,
}) => {
   const [isReadOnly, setIsReadOnly] = useState(false);
   const formats = useMemo(() => {
      return [
         'bold',
         'italic',
         'underline',
         'blockquote',
         'list',
         'bullet',
         'indent',
         'ordered',
         'align',
      ];
   }, []);
 
 
   const currentModules = useMemo(() => {
      return {
         toolbar: [
            ['bold', 'italic', 'underline'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'align': [] }],
         ],
      }; 
   }, []);
   const editorRef = useRef(null);
   const isFirstRender = useRef(true);
   const quillRef = useRef(null);


   const handleChange = (value) => {
      if (isFirstRender.current) {
         isFirstRender.current = false;
         return;
      }
      if ((isOtherPage || isTemplate) && isReadOnly) {
         onChange(value);
      } else if (isEmail) {
         onChange(value);
      }
   };

   
   const handleFocus = () => {
      setIsReadOnly(true);
   };
  
   const handleBlur = () => {
      setIsReadOnly(false);
   };
   return (
      <div
         ref={ editorRef }
         className={
            classNames(
               'quillInlineEditorSimple',
               {
                  'quillInlineEditorSimple__isTemplate': isTemplate,
                  'quillInlineEditorSimple__isEmail': isEmail,
                  'quillInlineEditorSimple__isOtherPage': isOtherPage,
               })
         }
      >
         <ReactQuill
            ref={ quillRef }
            value={ text }
            onChange={ handleChange }
            theme='bubble' 
            onFocus={ handleFocus }
            onBlur={ handleBlur }
            modules={ modules || currentModules }
            formats={ formats }
         /> 
      </div>
   );
};
 
QuillInlineEditorSimple.propTypes = {
   text: PropTypes.string,
   onChange: PropTypes.func,
   isTemplate: PropTypes.bool,
   modules: PropTypes.object,
   isOtherPage: PropTypes.bool,
   isEmail: PropTypes.bool,
};
 
export default QuillInlineEditorSimple;