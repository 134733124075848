import React from 'react';

export const ExportCSVMIcon = () => {
   return (
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
         <path fillRule='evenodd' clipRule='evenodd' d='M12.3534 3.31783C12.1582 3.12257 11.8416 3.12257 11.6463 3.31783L8.31494 6.64924C8.11967 6.8445 8.11967 7.16108 8.31494 7.35634C8.5102 7.55161 8.82678 7.55161 9.02204 7.35634L11.4999 4.87849V13.6656C11.4999 13.9417 11.7238 14.1656 11.9999 14.1656C12.276 14.1656 12.4999 13.9417 12.4999 13.6656V4.87849L14.9777 7.35634C15.173 7.55161 15.4896 7.55161 15.6849 7.35634C15.8801 7.16108 15.8801 6.8445 15.6849 6.64924L12.3534 3.31783ZM4.17139 13.6656C4.17139 13.3895 3.94753 13.1656 3.67139 13.1656C3.39524 13.1656 3.17139 13.3895 3.17139 13.6656V14.082V14.1032C3.17138 15.133 3.17138 15.9346 3.2207 16.5789C3.27076 17.2329 3.37377 17.7659 3.60207 18.2495C4.04747 19.193 4.80684 19.9523 5.75028 20.3977C6.23386 20.626 6.76689 20.729 7.42091 20.7791C8.0652 20.8284 8.86674 20.8284 9.89649 20.8284H9.89664H9.91777H14.082H14.1032H14.1033C15.1331 20.8284 15.9346 20.8284 16.5789 20.7791C17.2329 20.729 17.7659 20.626 18.2495 20.3977C19.193 19.9523 19.9523 19.193 20.3977 18.2495C20.626 17.7659 20.729 17.2329 20.7791 16.5789C20.8284 15.9346 20.8284 15.133 20.8284 14.1032V14.1032V14.082V13.6656C20.8284 13.3895 20.6046 13.1656 20.3284 13.1656C20.0523 13.1656 19.8284 13.3895 19.8284 13.6656V14.082C19.8284 15.1374 19.8281 15.9009 19.782 16.5026C19.7364 17.099 19.648 17.4953 19.4934 17.8226C19.147 18.5564 18.5564 19.147 17.8226 19.4934C17.4953 19.648 17.099 19.7364 16.5026 19.782C15.9009 19.8281 15.1374 19.8284 14.082 19.8284H9.91777C8.86236 19.8284 8.09893 19.8281 7.49722 19.782C6.9008 19.7364 6.50452 19.648 6.17719 19.4934C5.4434 19.147 4.85278 18.5564 4.50636 17.8226C4.35183 17.4953 4.26343 17.099 4.21778 16.5026C4.17173 15.9009 4.17139 15.1374 4.17139 14.082V13.6656Z' fill='#153833' />
      </svg>
   );
};

export const PluseNewLIcon = () => {
   return (
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
         <path fillRule='evenodd' clipRule='evenodd' d='M12 4.5C12.2761 4.5 12.5 4.72386 12.5 5V11.5H19C19.2761 11.5 19.5 11.7239 19.5 12C19.5 12.2761 19.2761 12.5 19 12.5H12.5V19C12.5 19.2761 12.2761 19.5 12 19.5C11.7239 19.5 11.5 19.2761 11.5 19V12.5H5C4.72386 12.5 4.5 12.2761 4.5 12C4.5 11.7239 4.72386 11.5 5 11.5H11.5V5C11.5 4.72386 11.7239 4.5 12 4.5Z' fill='white' />
      </svg>
   );
};

export const CommunityAddUserWhiteIcon = () => {
   return (
      <svg width='19' height='18' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
         <path fillRule='evenodd' clipRule='evenodd' d='M6.50008 1.16602C4.93527 1.16602 3.66675 2.43454 3.66675 3.99935C3.66675 5.56416 4.93527 6.83268 6.50008 6.83268C8.06489 6.83268 9.33342 5.56416 9.33342 3.99935C9.33342 2.43454 8.06489 1.16602 6.50008 1.16602ZM2.66675 3.99935C2.66675 1.88226 4.38299 0.166016 6.50008 0.166016C8.61717 0.166016 10.3334 1.88226 10.3334 3.99935C10.3334 6.11644 8.61717 7.83268 6.50008 7.83268C4.38299 7.83268 2.66675 6.11644 2.66675 3.99935ZM0.166748 14.666C0.166748 12.1807 2.18147 10.166 4.66675 10.166H8.33341C10.8187 10.166 12.8334 12.1807 12.8334 14.666C12.8334 16.4149 11.4156 17.8327 9.66675 17.8327H3.33342C1.58452 17.8327 0.166748 16.4149 0.166748 14.666ZM4.66675 11.166C2.73375 11.166 1.16675 12.733 1.16675 14.666C1.16675 15.8626 2.1368 16.8327 3.33342 16.8327H9.66675C10.8634 16.8327 11.8334 15.8626 11.8334 14.666C11.8334 12.733 10.2664 11.166 8.33341 11.166H4.66675ZM16.1667 4.83268C16.1667 4.55654 15.9429 4.33268 15.6667 4.33268C15.3906 4.33268 15.1667 4.55654 15.1667 4.83268V6.83268H13.1667C12.8906 6.83268 12.6667 7.05654 12.6667 7.33268C12.6667 7.60883 12.8906 7.83268 13.1667 7.83268H15.1667V9.83268C15.1667 10.1088 15.3906 10.3327 15.6667 10.3327C15.9429 10.3327 16.1667 10.1088 16.1667 9.83268V7.83268H18.1667C18.4429 7.83268 18.6667 7.60883 18.6667 7.33268C18.6667 7.05654 18.4429 6.83268 18.1667 6.83268H16.1667V4.83268Z' fill='white' />
      </svg>
   );
};

export const CommunityAddUserGreenIcon = () => {
   return (
      <svg width='19' height='18' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
         <path fillRule='evenodd' clipRule='evenodd' d='M6.50008 1.16602C4.93527 1.16602 3.66675 2.43454 3.66675 3.99935C3.66675 5.56416 4.93527 6.83268 6.50008 6.83268C8.06489 6.83268 9.33342 5.56416 9.33342 3.99935C9.33342 2.43454 8.06489 1.16602 6.50008 1.16602ZM2.66675 3.99935C2.66675 1.88226 4.38299 0.166016 6.50008 0.166016C8.61717 0.166016 10.3334 1.88226 10.3334 3.99935C10.3334 6.11644 8.61717 7.83268 6.50008 7.83268C4.38299 7.83268 2.66675 6.11644 2.66675 3.99935ZM0.166748 14.666C0.166748 12.1807 2.18147 10.166 4.66675 10.166H8.33341C10.8187 10.166 12.8334 12.1807 12.8334 14.666C12.8334 16.4149 11.4156 17.8327 9.66675 17.8327H3.33342C1.58452 17.8327 0.166748 16.4149 0.166748 14.666ZM4.66675 11.166C2.73375 11.166 1.16675 12.733 1.16675 14.666C1.16675 15.8626 2.1368 16.8327 3.33342 16.8327H9.66675C10.8634 16.8327 11.8334 15.8626 11.8334 14.666C11.8334 12.733 10.2664 11.166 8.33341 11.166H4.66675ZM16.1667 4.83268C16.1667 4.55654 15.9429 4.33268 15.6667 4.33268C15.3906 4.33268 15.1667 4.55654 15.1667 4.83268V6.83268H13.1667C12.8906 6.83268 12.6667 7.05654 12.6667 7.33268C12.6667 7.60883 12.8906 7.83268 13.1667 7.83268H15.1667V9.83268C15.1667 10.1088 15.3906 10.3327 15.6667 10.3327C15.9429 10.3327 16.1667 10.1088 16.1667 9.83268V7.83268H18.1667C18.4429 7.83268 18.6667 7.60883 18.6667 7.33268C18.6667 7.05654 18.4429 6.83268 18.1667 6.83268H16.1667V4.83268Z' fill='#24554E' />
      </svg>
   );
};

ExportCSVMIcon.propTypes = {};
PluseNewLIcon.propTypes = {};
CommunityAddUserWhiteIcon.propTypes = {};