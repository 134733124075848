import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import Container from '../AdminContainer';
import LeftBar from '../LeftBar';
import MobileHeader from '../MobileHeader';

const AdminLayout = ({ children, isShowTrial }) => {
   const childrenArray = React.Children.map(children, (child) => {
      return React.cloneElement(child, {});
   });
   return (
      <div className='adminLayout' style={ isShowTrial ? { height: 'calc(100vh - 40px)' } : {} }>
         {childrenArray}
      </div>
   );
};

AdminLayout.Container = Container;
AdminLayout.LeftBar = LeftBar;
AdminLayout.MobileHeader = MobileHeader;

AdminLayout.propTypes = {
   children: PropTypes.any,
   isShowTrial: PropTypes.bool,
};
export default AdminLayout;
