import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import Icon from 'components/elements/Icon';
import classNames from 'classnames';
import Text, { SIZES as txtSizes, TYPES as txtType } from 'components/elements/TextNew';
import Router from 'routes/router';
import { getWindowSize } from 'utils/getWindowSize';

const SidebarLink = ({
   style, icon, title, user, userImage, active, to, onClick,
   subMenu, blank, isPageBuilder, className, id, goTo, globalStatus, isBeta,
}) => {
   const sidebarClick = () => {
      if (globalStatus) {
         onClick();
      } else {
         goTo(Router.route('ADMIN_SUSPENDED').getCompiledPath());
      }
   };
   const { innerWidth } = getWindowSize();
   return (
      <li
         className={ `sidebarLink ${ className }` }
         style={ style }
         id={ id }
      >
         <a
            className={
               classNames(
                  'sidebarLink__content',
                  {
                     'sidebarLink__active': active,
                     'sidebarLink__subMenu': subMenu,
                     'small_sidebarLink__content': isPageBuilder,
                     'sidebarLink_w_icon': !icon && active,
                  }
               ) }
            href={ to }
            onClick={ onClick ? (e) => { e.preventDefault(); sidebarClick(); } : null }
            target={ blank ? '_blank' : '_self' }
            rel='noreferrer'
         >
            {
               user ? <img src={ userImage } alt='' className='sidebarLink__user-image' />
                  : (icon && <Icon name={ icon } className='sidebarLink__svg' />)
            }
            <Text
               size={ innerWidth < 1024 ? txtSizes.small : txtSizes.medium }
               type={ innerWidth < 1024 ? txtType.regularDefault : txtType.mediumTitle }
               inner={ title }
               className={ `${ isPageBuilder ? 'small_sidebarLink_text' : '' } ` }
               style={ {
                  color: '#444C4B',
               } }
            />
            {isBeta && (
               <Text
                  size={ txtSizes.small }
                  type={ txtType.regular }
                  inner='Beta'
                  className={ `${ isPageBuilder ? 'small_sidebarLink_text beta' : 'beta' }` }
               />
            )}
         </a>
      </li>
   );
};

SidebarLink.propTypes = {
   title: PropTypes.string,
   icon: PropTypes.string,
   to: PropTypes.string,
   active: PropTypes.any,
   onClick: PropTypes.func,
   userImage: PropTypes.string,
   user: PropTypes.bool,
   style: PropTypes.object,
   subMenu: PropTypes.bool,
   blank: PropTypes.bool,
   isPageBuilder: PropTypes.bool,
   className: PropTypes.string,
   goTo: PropTypes.func,
   globalStatus: PropTypes.any,
   id: PropTypes.string,
   isBeta: PropTypes.bool,
};

SidebarLink.defaultProps = {
   isPageBuilder: false,
   className: '',
   isBeta: false,
};

export default SidebarLink;
