export const GET_COMMUNITY_START = 'community/GET_COMMUNITY_START';
export const GET_COMMUNITY_END = 'community/GET_COMMUNITY_END';
export const GET_COMMUNITY_COMPLETED = 'community/GET_COMMUNITY_COMPLETED';

export const CREATE_ROOM_START = 'community/CREATE_ROOM_START';
export const CREATE_ROOM_END = 'community/CREATE_ROOM_END';
export const CREATE_ROOM_COMPLETED = 'community/CREATE_ROOM_COMPLETED';

export const CREATE_EVENT_START = 'community/CREATE_EVENT_START';
export const CREATE_EVENT_END = 'community/CREATE_EVENT_END';
export const CREATE_EVENT_COMPLETED = 'community/CREATE_EVENT_COMPLETED';

export const EVENT_CHANGES_START = 'community/EVENT_CHANGES_START';
export const EVENT_CHANGES_END = 'community/EVENT_CHANGES_END';

export const EVENT_PIN_OR_UNPIN_COMPLETED = 'community/EVENT_PIN_OR_UNPIN_COMPLETED';

export const ARCHIVE_EVENT_COMPLETED = 'community/ARCHIVE_EVENT_COMPLETED';

export const GET_ROOM_START = 'community/GET_ROOM_START';
export const GET_ROOM_COMPLETED = 'community/GET_ROOM_COMPLETED';
export const GET_ROOM_FAILED = 'community/GET_ROOM_FAILED';

export const CHANGE_SORT_OPTIONS = 'community/CHANGE_SORT_OPTIONS';

export const SELECT_EVENT_VIEW = 'community/SELECT_EVENT_VIEW';

export const FILTER_ROOM_START = 'community/FILTER_ROOM_START';
export const FILTER_ROOM_COMPLETED = 'community/FILTER_ROOM_COMPLETED';
export const FILTER_ROOM_FAILED = 'community/FILTER_ROOM_FAILED';

export const EVENT_SETTINGS_GET_START = 'community/EVENT_SETTINGS_GET_START';
export const EVENT_SETTINGS_GET_FAILED = 'community/EVENT_SETTINGS_GET_FAILED';
export const EVENT_SETTINGS_GET_COMPLETED = 'community/EVENT_SETTINGS_GET_COMPLETED';

export const EVENT_SETTINGS_PUT_START = 'community/EVENT_SETTINGS_PUT_START';
export const EVENT_SETTINGS_PUT_COMPLETED = 'community/EVENT_SETTINGS_PUT_COMPLETED';
export const EVENT_SETTINGS_PUT_FAILED = 'community/EVENT_SETTINGS_PUT_FAILED';

export const DELETE_ROOM_START = 'community/DELETE_ROOM_START';
export const DELETE_ROOM_COMPLETED = 'community/DELETE_ROOM_COMPLETED';
export const DELETE_ROOM_FAILED = 'community/DELETE_ROOM_FAILED';

export const CREATE_POST_START = 'community/CREATE_POST_START';
export const CREATE_POST_COMPLETED = 'community/CREATE_POST_COMPLETED';
export const CREATE_POST_FAILED = 'community/CREATE_POST_FAILED';

export const INVITE_MEMBER_START = 'community/INVITE_MEMBER_START';
export const INVITE_MEMBER_COMPLETED = 'community/INVITE_MEMBER_COMPLETED';
export const INVITE_MEMBER_FAILED = 'community/INVITE_MEMBER_FAILED';

export const POST_COMMENT_COMPLETED = 'community/POST_COMMENT_COMPLETED';
export const POST_REPLY_COMMENT_COMPLETED = 'community/POST_REPLY_COMMENT_COMPLETED';

export const EVENT_COMMENT_COMPLETED = 'community/EVENT_COMMENT_COMPLETED';
export const EVENT_COMMENT_LIKE_COMPLETED = 'community/EVENT_COMMENT_LIKE_COMPLETED';
export const EVENT_REPLY_COMMENT_COMPLETED = 'community/EVENT_REPLY_COMMENT_COMPLETED';

export const POST_LIKE_COMPLETED = 'community/POST_LIKE_COMPLETED';

export const POST_COMMENT_LIKE_COMPLETED = 'community/POST_COMMENT_LIKE_COMPLETED';
export const POST_COMMENT_DELETE_COMPLETED = 'community/POST_COMMENT_DELETE_COMPLETED';

export const POST_EXTERNAL_LINK_CREATE_COMPLETED = 'community/POST_EXTERNAL_LINK_CREATE_COMPLETED';
export const POST_EXTERNAL_LINK_DELETE_COMPLETED = 'community/POST_EXTERNAL_LINK_DELETE_COMPLETED';

export const EMAIL_NOTIFICATIONS_CHANGES_COMPLETED = 'community/EMAIL_NOTIFICATIONS_CHANGES_COMPLETED';

export const COMMUNITY_SETTINGS_COMPLETED = 'community/COMMUNITY_SETTINGS_COMPLETED';

export const USER_VOTE_COMPLETED = 'community/USER_VOTE_COMPLETED';

export const USER_GET_COMPLETED = 'community/USER_GET_COMPLETED';
export const USER_GET_START = 'community/USER_GET_START';

export const USER_SUBSCRIBE_COMPLETED = 'community/USER_SUBSCRIBE_COMPLETED';

export const USER_FILTER_COMPLETED = 'community/USER_FILTER_COMPLETED';

export const GET_MEMBERS_PAGE_INFORMATION_START = 'community/GET_MEMBERS_PAGE_INFORMATION_START';
export const GET_MEMBERS_PAGE_INFORMATION_COMPLETED = 'community/GET_MEMBERS_PAGE_INFORMATION_COMPLETED';

export const MEMBERS_LIST_SUBSCRIBE_COMPLETED = 'community/MEMBERS_LIST_SUBSCRIBE_COMPLETED';

export const MEMBERS_LIST_DELETE_COMPLETED = 'community/MEMBERS_LIST_DELETE_COMPLETED';

export const NOTIFICATION_MARK_READ = 'community/NOTIFICATION_MARK_READ';

export const GET_MESSAGES_START = 'community/GET_MESSAGES_START';
export const GET_MESSAGES_COMPLETED = 'community/GET_MESSAGES_COMPLETED';
export const GET_MESSAGES_FAILED = 'community/GET_MESSAGES_FAILED';

export const FILTER_MESSAGES_COMPLETED = 'community/FILTER_MESSAGES_COMPLETED';

export const GET_MESSAGES_WITH_MEMBER_COMPLETED = 'community/GET_MESSAGES_WITH_MEMBER_COMPLETED';

export const UPDATE_MESSAGES_BY_SOCKET = 'community/UPDATE_MESSAGES_BY_SOCKET';

export const MESSENGER_CLEAR_CONVERSATION_COMPLETED = 'community/MESSENGER_CLEAR_CONVERSATION_COMPLETED';

export const UPDATE_UNREAD_MESSAGES_COUNT = 'community/UPDATE_UNREAD_MESSAGES_COUNT';

export const UPDATE_SEARCH_INPUTS = 'community/UPDATE_SEARCH_INPUTS';

export const POST_DELETE_COMPLETED = 'community/POST_DELETE_COMPLETED';
export const EVENT_COMMENT_DELETE_COMPLETED = 'community/EVENT_COMMENT_DELETE_COMPLETED';

export const RESET_SELECTED_ROOM = 'community/RESET_SELECTED_ROOM';
