/* eslint-disable camelcase */
import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import SidebarGroup from 'components/elements/sidebar/SidebarGroup';
import SidebarLink from 'components/elements/sidebar/SidebarLink';
import Navigation from 'components/elements/Navigation';
import Router from 'routes/router';
import LogOut from 'components/modules/logOutPopup';
import ProgressBar from 'components/modules/progressBar';
import Text, { TYPE as TextType, SIZES as TextSize } from 'components/elements/Text';
import PricingPopup from 'components/elements/PricingPopup';

import './index.scss';
import { useSelector } from 'react-redux';
import { siteInfoSelector } from 'state/modules/common/selectors';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const AdminSidebar = ({
   sidebarProps,
}) => {
   const {
      goTo, locationPath, isPageBuilder, handleLogout, authUser, goToMyAccount,
      userChangedData, status, isOneTimeUser, openLogOutPopup, logoutPopupIsOpen,
      gbDefaultSize, gbSize, progresPercent, isBarSmall, app, mainApp, metas,
   } = sidebarProps;
   const [showPopup, setShowPopup] = useState(false);
   const history = useHistory();
   const [popupTitle, setPopupTitle] = useState('');

   const { permissions } = useSelector(siteInfoSelector);

   const handleShowPopup = (title) => {
      if (!Array.isArray(permissions)) {
         if (permissions.affiliate_program && title === 'affiliate' && !mainApp.plan_name.includes('starter') && !mainApp.plan_name.includes('essential')) {
            goTo(Router.route('ADMIN_AFFILIATE').getCompiledPath());
         } else if (permissions.automations && title === 'automations') {
            setPopupTitle('Automations');
            goTo(Router.route('ADMIN_AUTOMATIONS').getCompiledPath());
         } else {
            setPopupTitle(title);
            setShowPopup(true);
         }
      } else if (title === 'affiliate') {
         goTo(Router.route('ADMIN_AFFILIATE').getCompiledPath());
      } else if (title === 'automations') {
         goTo(Router.route('ADMIN_AUTOMATIONS').getCompiledPath());
      }
   };

   const handleGoToMediaLibrary = () => {
      if (!Array.isArray(permissions)) {
         if (permissions.media_library) {
            goTo(Router.route('MEDIA_LIBRARY').getCompiledPath());
         } else {
            setShowPopup(true);
            setPopupTitle('Media Library');
         }
      } else {
         goTo(Router.route('MEDIA_LIBRARY').getCompiledPath());
      }
   };

   const handleClosePopup = () => {
      setShowPopup(false);
   };

   const handleGoToHelpCenter = () => {
      if (!Array.isArray(permissions)) {
         if (permissions.help_center) {
            window.open('https://support.miestro.com');
         } else {
            setShowPopup('Help Center');
            setShowPopup(true);
         }
      } else {
         window.open('https://support.miestro.com');
      }
   };

   const handleGoToPageViews = () => {
      if (!Array.isArray(permissions)) {
         if (permissions.page_views) {
            goTo(Router.route('ADMIN_PAGE_VIEWS').getCompiledPath());
         } else {
            setShowPopup('Page Views');
            setShowPopup(true);
         }
      } else {
         goTo(Router.route('ADMIN_PAGE_VIEWS').getCompiledPath());
      }
   };

   const handleNavigateToPlansPage = () => {
      history.push('/admin/account');
      localStorage.setItem('showPlans', 'showPlans');
   };

   const handleNavigateEmails = () => {
      if (!permissions.email_marketing) {
         setPopupTitle('Email Marketing');
         setShowPopup(true);
      } else {
         goTo(`${ Router.route('ADMIN_EMAILS').getCompiledPath() }#single_emails`);
      }
   };

   return (
      <ul className='sidebar__menu'>
         {
            showPopup && createPortal(<PricingPopup
               handleClosePopup={ handleClosePopup }
               popupTitle={ popupTitle }
            />, document.body)
         }
         <Navigation
            isPageBuilder={ isPageBuilder }
         >
            <SidebarLink
               icon='Dashboard'
               title='Dashboard'
               to={ Router.route('ADMIN_DASHBOARD').getCompiledPath() }
               onClick={ () => goTo(Router.route('ADMIN_DASHBOARD').getCompiledPath()) }
               active={ Router.route('ADMIN_DASHBOARD').match(locationPath) }
               isPageBuilder={ isPageBuilder || isBarSmall }
               goTo={ goTo }
               globalStatus={ status }
            />
            <SidebarGroup
               icon='Classes'
               title='Products'
               isPageBuilder={ isPageBuilder || isBarSmall }
               onClick={ (isPageBuilder || isBarSmall) ? () => goTo(Router.route('ADMIN_COURSES').getCompiledPath()) : () => { } }
            >
               <SidebarLink
                  title='My Products'
                  subMenu
                  to={ Router.route('ADMIN_COURSES').getCompiledPath() }
                  onClick={ () => goTo(Router.route('ADMIN_COURSES').getCompiledPath()) }
                  active={ Router.route('ADMIN_COURSES').match(locationPath) }
                  goTo={ goTo }
                  globalStatus={ status }
               />
               {/* <SidebarLink
                  title='Email Notifications'
                  subMenu
                  to={ Router.route('ADMIN_TEMPLATES').getCompiledPath() }
                  onClick={ () => goTo(Router.route('ADMIN_TEMPLATES').getCompiledPath()) }
                  active={ Router.route('ADMIN_TEMPLATES').match(locationPath) }
                  goTo={ goTo }
                  globalStatus={ status }
               /> */}
               <SidebarLink
                  title='Media Library'
                  subMenu
                  to={ Router.route('MEDIA_LIBRARY').getCompiledPath() }
                  onClick={ handleGoToMediaLibrary }
                  active={ Router.route('MEDIA_LIBRARY').match(locationPath) }
                  isPageBuilder={ isPageBuilder }
                  goTo={ goTo }
                  globalStatus={ status }
               />
               <SidebarLink
                  title='Other Pages'
                  subMenu
                  to={ Router.route('ADMIN_OTHER_PAGES').getCompiledPath() }
                  onClick={ () => goTo(Router.route('ADMIN_OTHER_PAGES').getCompiledPath()) }
                  active={ Router.route('ADMIN_OTHER_PAGES').match(locationPath) }
                  goTo={ goTo }
                  globalStatus={ status }
               />
               <SidebarLink
                  title='Landing Pages'
                  subMenu
                  to={ Router.route('ADMIN_LANDING_PAGES').getCompiledPath() }
                  onClick={ () => goTo(Router.route('ADMIN_LANDING_PAGES').getCompiledPath()) }
                  active={ Router.route('ADMIN_LANDING_PAGES').match(locationPath) }
                  isPageBuilder={ isPageBuilder }
                  goTo={ goTo }
                  globalStatus={ status }
               />
               {/* <SidebarLink
                  title='Live Stream'
                  subMenu
                  to={ Router.route('ADMIN_LIVE_STREAM').getCompiledPath() }
                  onClick={ () => goTo(Router.route('ADMIN_LIVE_STREAM').getCompiledPath()) }
                  active={ Router.route('ADMIN_LIVE_STREAM').match(locationPath) }
                  isPageBuilder={ isPageBuilder }
                  goTo={ goTo }
                  globalStatus={ status }
               /> */}
               <SidebarLink
                  title='Portal Themes'
                  subMenu
                  to={ Router.route('ADMIN_SCHOOL_ROOM').getCompiledPath() }
                  onClick={ () => goTo(Router.route('ADMIN_SCHOOL_ROOM').getCompiledPath()) }
                  active={ Router.route('ADMIN_SCHOOL_ROOM').match(locationPath) }
                  isPageBuilder={ isPageBuilder || isBarSmall }
                  goTo={ goTo }
                  globalStatus={ status }
               />
               <SidebarLink
                  title='Quizzes'
                  subMenu
                  to={ Router.route('ADMIN_QUIZZES').getCompiledPath() }
                  onClick={ () => goTo(Router.route('ADMIN_QUIZZES').getCompiledPath()) }
                  active={ Router.route('ADMIN_QUIZZES').match(locationPath) }
                  isPageBuilder={ isPageBuilder || isBarSmall }
                  goTo={ goTo }
                  globalStatus={ status }
               />
            </SidebarGroup>
            <SidebarLink
               icon='Member'
               title='Members'
               to={ Router.route('ADMIN_MEMBERS').getCompiledPath() }
               onClick={ () => goTo(Router.route('ADMIN_MEMBERS').getCompiledPath()) }
               active={ Router.route('ADMIN_MEMBERS').match(locationPath) }
               isPageBuilder={ isPageBuilder || isBarSmall }
               goTo={ goTo }
               globalStatus={ status }
            />
            <SidebarGroup
               icon='Report'
               title='Analytics'
               isPageBuilder={ isPageBuilder || isBarSmall }
               onClick={ (isPageBuilder || isBarSmall) ? () => goTo(Router.route('ADMIN_TRANSACTIONS').getCompiledPath()) : () => { } }
            >
               <SidebarLink
                  title='Dashboard'
                  subMenu
                  to={ Router.route('ADMIN_REPORTS_DASHBOARD').getCompiledPath() }
                  onClick={ () => goTo(Router.route('ADMIN_REPORTS_DASHBOARD').getCompiledPath()) }
                  active={ Router.route('ADMIN_REPORTS_DASHBOARD').match(locationPath) }
                  goTo={ goTo }
                  globalStatus={ status }
               />
               <SidebarLink
                  title='Subscription Metrics'
                  subMenu
                  to={ Router.route('ADMIN_SUBSCRIPTION_METRICS').getCompiledPath() }
                  onClick={ () => goTo(Router.route('ADMIN_SUBSCRIPTION_METRICS').getCompiledPath()) }
                  active={ Router.route('ADMIN_SUBSCRIPTION_METRICS').match(locationPath) }
                  goTo={ goTo }
                  globalStatus={ status }
               />
               <SidebarLink
                  title='Net Revenue'
                  subMenu
                  to={ Router.route('ADMIN_NET_REVENUE').getCompiledPath() }
                  onClick={ () => goTo(Router.route('ADMIN_NET_REVENUE').getCompiledPath()) }
                  active={ Router.route('ADMIN_NET_REVENUE').match(locationPath) }
                  goTo={ goTo }
                  globalStatus={ status }
               />
               <SidebarLink
                  title='Page Views'
                  subMenu
                  to={ Router.route('ADMIN_PAGE_VIEWS').getCompiledPath() }
                  onClick={ () => handleGoToPageViews() }
                  active={ Router.route('ADMIN_PAGE_VIEWS').match(locationPath) }
                  goTo={ goTo }
                  globalStatus={ status }
               />
               <SidebarLink
                  title='Product Progress'
                  subMenu
                  to={ Router.route('ADMIN_CLASS_PROGRESS').getCompiledPath() }
                  onClick={ () => goTo(Router.route('ADMIN_CLASS_PROGRESS').getCompiledPath()) }
                  active={ Router.route('ADMIN_CLASS_PROGRESS').match(locationPath) }
                  goTo={ goTo }
                  globalStatus={ status }
               />
               <SidebarLink
                  title='Transactions'
                  subMenu
                  to={ Router.route('ADMIN_TRANSACTIONS').getCompiledPath() }
                  onClick={ () => goTo(Router.route('ADMIN_TRANSACTIONS').getCompiledPath()) }
                  active={ Router.route('ADMIN_TRANSACTIONS').match(locationPath) }
                  goTo={ goTo }
                  globalStatus={ status }
               />
               <SidebarLink
                  title='Refunds'
                  subMenu
                  to={ Router.route('ADMIN_REFOUNDS').getCompiledPath() }
                  onClick={ () => goTo(Router.route('ADMIN_REFOUNDS').getCompiledPath()) }
                  active={ Router.route('ADMIN_REFOUNDS').match(locationPath) }
                  goTo={ goTo }
                  globalStatus={ status }
               />
               <SidebarLink
                  title='Video Metrics'
                  subMenu
                  to={ Router.route('ADMIN_VIDEO_METRICS').getCompiledPath() }
                  onClick={ () => goTo(Router.route('ADMIN_VIDEO_METRICS').getCompiledPath()) }
                  active={ Router.route('ADMIN_VIDEO_METRICS').match(locationPath) }
                  goTo={ goTo }
                  globalStatus={ status }
               />
               {!!Number.parseFloat(metas.email_tracking) && (
                  <SidebarLink
                     title='Emails'
                     subMenu
                     to={ Router.route('ADMIN_EMAIL_TRACKING').getCompiledPath() }
                     onClick={ () => goTo(Router.route('ADMIN_EMAIL_TRACKING').getCompiledPath()) }
                     active={ Router.route('ADMIN_EMAIL_TRACKING').match(locationPath) }
                     goTo={ goTo }
                     globalStatus={ status }
                  />
               )}
            </SidebarGroup>
            <SidebarGroup
               icon='SalesNew'
               title='Sales'
               isPageBuilder={ isPageBuilder || isBarSmall }
               onClick={ (isPageBuilder || isBarSmall) ? () => goTo(Router.route('ADMIN_COURSES').getCompiledPath()) : () => { } }
            >
               <SidebarLink
                  title='Membership'
                  subMenu
                  to={ Router.route('ADMIN_MEMBERSHIP_CREATE').getCompiledPath() }
                  onClick={ () => goTo(Router.route('ADMIN_MEMBERSHIP_CREATE').getCompiledPath()) }
                  active={ Router.route('ADMIN_MEMBERSHIP_CREATE').match(locationPath) }
                  goTo={ goTo }
                  globalStatus={ status }
               />
               <SidebarLink
                  title='Bundles'
                  subMenu
                  to={ Router.route('ADMIN_PLANS').getCompiledPath() }
                  onClick={ () => goTo(Router.route('ADMIN_PLANS').getCompiledPath()) }
                  active={ Router.route('ADMIN_PLANS').match(locationPath) }
                  goTo={ goTo }
                  globalStatus={ status }
               />
               <SidebarLink
                  title='Coupons'
                  subMenu
                  to={ Router.route('ADMIN_COUPONS').getCompiledPath() }
                  onClick={ () => goTo(Router.route('ADMIN_COUPONS').getCompiledPath()) }
                  active={ Router.route('ADMIN_COUPONS').match(locationPath) }
                  goTo={ goTo }
                  globalStatus={ status }
               />
               <SidebarLink
                  title='Affiliate Program'
                  subMenu
                  to={ Router.route('ADMIN_AFFILIATE').getCompiledPath() }
                  onClick={ () => handleShowPopup('affiliate') }
                  active={ Router.route('ADMIN_AFFILIATE').match(locationPath) }
                  goTo={ goTo }
                  globalStatus={ status }
               />
            </SidebarGroup>

            <SidebarGroup
               icon='Promotion'
               title='Promotions'
               isPageBuilder={ isPageBuilder || isBarSmall }
               onClick={ (isPageBuilder || isBarSmall) ? () => goTo(Router.route('ADMIN_BLOG').getCompiledPath()) : () => { } }
            >
               <SidebarLink
                  title='Emails'
                  subMenu
                  to={ `${ Router.route('ADMIN_EMAILS').getCompiledPath() }#single_emails` }
                  onClick={ handleNavigateEmails }
                  active={ Router.route('ADMIN_EMAILS').match(locationPath) }
                  goTo={ goTo }
                  globalStatus={ status }
               />
               <SidebarLink
                  title='Blog'
                  subMenu
                  to={ Router.route('ADMIN_BLOG').getCompiledPath() }
                  onClick={ () => goTo(Router.route('ADMIN_BLOG').getCompiledPath()) }
                  active={ Router.route('ADMIN_BLOG').match(locationPath) }
                  isPageBuilder={ isPageBuilder }
                  goTo={ goTo }
                  globalStatus={ status }
               />
               <SidebarLink
                  title='Certificates'
                  subMenu
                  to={ Router.route('ADMIN_CERTIFICATES').getCompiledPath() }
                  onClick={ () => goTo(Router.route('ADMIN_CERTIFICATES').getCompiledPath()) }
                  active={ Router.route('ADMIN_CERTIFICATES').match(locationPath) }
                  isPageBuilder={ isPageBuilder }
                  goTo={ goTo }
                  globalStatus={ status }
               />
               {/* <SidebarLink
                  title='Gamification'
                  subMenu
                  to={ Router.route('ADMIN_GAMIFICATION').getCompiledPath() }
                  onClick={ () => goTo(Router.route('ADMIN_GAMIFICATION').getCompiledPath()) }
                  active={ Router.route('ADMIN_GAMIFICATION').match(locationPath) }
                  isPageBuilder={ isPageBuilder }
                  goTo={ goTo }
                  globalStatus={ status }
               /> */}
               {/* <SidebarLink
                  title='Affiliate'
                  subMenu
                  onClick={ () => window.open('https://miestro.firstpromoter.com', '_blank') }
                  isPageBuilder={ isPageBuilder }
                  goTo={ goTo }
                  globalStatus={ status }
               /> */}
            </SidebarGroup>
            {/* { !isOneTimeUser && app.is_main ? (
               <SidebarLink
                  icon='Plan'
                  title='Plans'
                  to={ Router.route('ADMIN_PLANS').getCompiledPath() }
                  onClick={ () => goTo(Router.route('ADMIN_PLANS').getCompiledPath()) }
                  active={ Router.route('ADMIN_PLANS').match(locationPath) }
                  isPageBuilder={ isPageBuilder || isBarSmall }
                  goTo={ goTo }
                  globalStatus={ true }
               />
            ) : <></>
            } */}
            <SidebarLink
               title='Automations'
               icon='Automation'
               to={ Router.route('ADMIN_AUTOMATIONS').getCompiledPath() }
               onClick={ () => handleShowPopup('automations') }
               active={ Router.route('ADMIN_AUTOMATIONS').match(locationPath) }
               isPageBuilder={ isPageBuilder || isBarSmall }
               goTo={ goTo }
               globalStatus={ status }
               isBeta={ false }
            />
            <SidebarLink
               title='App Builder'
               icon='SideBarMenuPhone'
               to={ Router.route('ADMIN_APP_BUILDER').getCompiledPath() }
               onClick={ () => goTo(Router.route('ADMIN_APP_BUILDER').getCompiledPath()) }
               active={ Router.route('ADMIN_APP_BUILDER').match(locationPath) }
               isPageBuilder={ isPageBuilder || isBarSmall }
               goTo={ goTo }
               globalStatus={ status }
               isBeta={ false }
            />

            <SidebarLink
               icon='Help'
               title='Help'
               className='settings__margin'
               blank
               isPageBuilder={ isPageBuilder || isBarSmall }
               goTo={ goTo }
               globalStatus={ status }
               onClick={ handleGoToHelpCenter }
            />
            <SidebarLink
               icon='ChangeLog'
               title='Updates'
               id='changeLog'
               isPageBuilder={ isPageBuilder || isBarSmall }
               globalStatus={ true }
               onClick={ () => {
                  document.getElementById('loopedinSelector').click();
                  const loopedinSideBar = document.getElementById('loopedin-sidebar');
                  loopedinSideBar.classList.add('loopedin-sidebar-show');
                  loopedinSideBar.addEventListener('click', () => {
                     loopedinSideBar.classList.remove('loopedin-sidebar-show');
                  });
               } }
            />
            <SidebarLink
               icon='Setting'
               title='Settings'
               to={ Router.route('ADMIN_SETTINGS').getCompiledPath() }
               onClick={ () => goTo(Router.route('ADMIN_SETTINGS').getCompiledPath()) }
               active={ Router.route('ADMIN_SETTINGS').match(locationPath) }
               isPageBuilder={ isPageBuilder || isBarSmall }
               goTo={ goTo }
               globalStatus={ status }
            />
            {
               mainApp.plan_name && mainApp.plan_name.includes('starter') && (
                  <div onClick={ handleNavigateToPlansPage } role='presentation' className='upgrade__now'>
                     <span>Upgrade Now</span>
                  </div>
               )
            }
            {isOneTimeUser && !isPageBuilder && !isBarSmall && !app.unlimited_storage && !mainApp.unlimited_storage ? (
               <div style={ { textAlign: 'right', paddingBottom: '15px' } }>
                  <Text
                     type={ TextType.normal }
                     size={ TextSize.small }
                     inner={ `${ (gbDefaultSize - gbSize).toFixed(2) } of ${ gbDefaultSize }GB` }
                     className='planPriceValue'
                     style={ { padding: '0 12px', fontSize: '14px' } }
                  />
                  <ProgressBar
                     bgcolor='#7cb740'
                     completed={ progresPercent }
                  />
               </div>
            ) : <></>
            }
            <div style={ { position: 'relative' } }>
               <div
                  style={ {
                     position: 'absolute',
                     width: '100%',
                     height: '1px',
                     background: '#E7E9E9',
                     marginLeft: '0px',
                  } }
               />
               <LogOut
                  handleLogout={ handleLogout }
                  goToMyAccount={ goToMyAccount }
                  goTo={ goTo }
                  globalStatus={ status }
                  openLogOutPopup={ openLogOutPopup }
                  logoutPopupIsOpen={ logoutPopupIsOpen }
               >
                  <SidebarGroup
                     user={ true }
                     style={ {
                        marginTop: '2px',
                     } }
                     userImage={ userChangedData.picture_src ? userChangedData.picture_src : authUser.picture_full_src }
                     title={ userChangedData.name ? userChangedData.name : authUser.name }
                     isPageBuilder={ isPageBuilder || isBarSmall }
                  />
               </LogOut>
            </div>

         </Navigation>
      </ul>
   );
};

AdminSidebar.propTypes = {
   sidebarProps: PropTypes.object,
};

export default AdminSidebar;
