const LOGIN = 'LOGIN';
const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
const RESET_PASSWORD = 'RESET_PASSWORD';
const SIGNUP_STUDENT = 'SIGNUP_STUDENT';
const AUTH_ATTEMPT = 'AUTH_ATTEMPT';
const ADMIN_DASHBOARD = 'ADMIN_DASHBOARD';
const ADMIN_COURSES = 'ADMIN_COURSES';
const ADMIN_COURSES_EDIT = 'ADMIN_COURSES_EDIT';
const ADMIN_EMAILS = 'ADMIN_EMAILS';
// const ADMIN_TEMPLATES = `ADMIN_TEMPLATES`;
const ADMIN_HELP = 'ADMIN_HELP';
const ADMIN_MEMBERS = 'ADMIN_MEMBERS';
const ADMIN_REPORTS = 'ADMIN_REPORTS';
const ADMIN_PLANS = 'ADMIN_PLANS';
const ADMIN_AFFILATE_PROGRAM = 'ADMIN_AFFILATE_PROGRAM';
const ADMIN_AFFILATES = 'ADMIN_AFFILATES';
// const ADMIN_GAMIFICATION = `ADMIN_GAMIFICATION`;
const ADMIN_SITE = 'ADMIN_SITE';
const ADMIN_SETTINGS = 'ADMIN_SETTINGS';
const ADMIN_SUSPENDED = 'ADMIN_SUSPENDED';
const MEMBER_ACCOUNT = 'MEMBER_ACCOUNT';
const COURSES = 'COURSES';
const STUDENTS_ROOM = 'STUDENTS_ROOM';
const STUDENTS_VIDEOROOM = 'STUDENTS_VIDEOROOM';
const STUDENTS_PLAYLISTROOM = 'STUDENTS_PLAYLISTROOM';
const MEMBER_SECURE_COURSE_LOGIN = 'MEMBER_SECURE_COURSE_LOGIN';
const MEMBER_SECURE_LOGIN = 'MEMBER_SECURE_LOGIN';
const ADMIN_SETTINGS_VIDEO = 'ADMIN_SETTINGS_VIDEO';
const CHECKOUT = 'CHECKOUT';
const ADMIN_REVENUE_REPORTS = 'ADMIN_REVENUE_REPORTS';
const ADMIN_TRANSACTIONS = 'ADMIN_TRANSACTIONS';
const ADMIN_REPORTS_DASHBOARD = 'ADMIN_REPORTS_DASHBOARD';
const ADMIN_NET_REVENUE = 'ADMIN_NET_REVENUE';
const ADMIN_SUBSCRIPTION_METRICS = 'ADMIN_SUBSCRIPTION_METRICS';
const ADMIN_PAGE_VIEWS = 'ADMIN_PAGE_VIEWS';
const ADMIN_VIDEOLIBRARY = 'ADMIN_VIDEOLIBRARY';
const MEMBER_UNSUBSCRIBE_EMAIL = 'MEMBER_UNSUBSCRIBE_EMAIL';
const MEDIA_LIBRARY = 'MEDIA_LIBRARY';
const ADMIN_AUTOMATIONS = 'ADMIN_AUTOMATIONS';
const ADMIN_AUTOMATION_EDIT = 'ADMIN_AUTOMATION_EDIT';
const ADMIN_APP_BUILDER = 'ADMIN_APP_BUILDER';
const ADMIN_BLOG = 'ADMIN_BLOG';
const ADMIN_BLOG_EDIT = 'ADMIN_BLOG_EDIT';
const BLOG_PREVIEW = 'BLOG_PREVIEW';
const BLOG_LISTING = 'BLOG_LISTING';
const ADMIN_CERTIFICATES = 'ADMIN_CERTIFICATES';
const ADMIN_CREATE_CERTIFICATE = 'ADMIN_CREATE_CERTIFICATE';
const ADMIN_EDIT_CERTIFICATE = 'ADMIN_EDIT_CERTIFICATE';
const ADMIN_SETTINGS_CERTIFICATE = 'ADMIN_SETTINGS_CERTIFICATE';
const ADMIN_REORDER_COURSES = 'ADMIN_REORDER_COURSES';
const THANK_YOU = 'THANK_YOU';
const THANK_YOU_PREVIEW = 'THANK_YOU_PREVIEW';
const ADMIN_LANDING_PAGES = 'ADMIN_LANDING_PAGES';
const ADMIN_LANDING_PAGES_CREATE = 'ADMIN_LANDING_PAGES_CREATE';
const ADMIN_LANDING_PAGES_EDIT = 'ADMIN_LANDING_PAGES_EDIT';
const ADMIN_LANDING_PAGES_DETAILS = 'ADMIN_LANDING_PAGES_DETAILS';
const ADMIN_LIVE_STREAM = 'ADMIN_LIVE_STREAM';
const LANDING_PAGES_PREVIEW = 'LANDING_PAGES_PREVIEW';
const ADMIN_COURSES_EDIT_CHECKOUT = 'ADMIN_COURSES_EDIT_CHECKOUT';
const CHECKOUT_PREVIEW = 'CHECKOUT_PREVIEW';
const CHECKOUT_TEMP_PREVIEW = 'CHECKOUT_TEMP_PREVIEW';
const ADMIN_SCHOOL_ROOM = 'ADMIN_SCHOOL_ROOM';
const ADMIN_SCHOOL_ROOM_CUSTOMIZE = 'ADMIN_SCHOOL_ROOM_CUSTOMIZE';
const ADMIN_SCHOOL_ROOM_EDIT = 'ADMIN_SCHOOL_ROOM_EDIT';
const ADMIN_SCHOOL_ROOM_SETTINGS = 'ADMIN_SCHOOL_ROOM_SETTINGS';
const SCHOOLROOM_PREVIEW = 'SCHOOLROOM_PREVIEW';
const MEMBER_SECURE_LOGIN_THANK_YOU = 'MEMBER_SECURE_LOGIN_THANK_YOU';
const SCHOOLROOM_TEMP_PREVIEW = 'SCHOOLROOM_TEMP_PREVIEW';
const OTHER_PAGES_PREVIEW = 'OTHER_PAGES_PREVIEW';
const ADMIN_REFOUNDS = 'ADMIN_REFOUNDS';
const ADMIN_VIDEO_METRICS = 'ADMIN_VIDEO_METRICS';
const ADMIN_CLASS_PROGRESS = 'ADMIN_CLASS_PROGRESS';
const ADMIN_CLASS__PROGRESS_VIEW = 'ADMIN_CLASS_PROGRESS_VIEW';
const ADMIN_MEMBER_VIEW = 'ADMIN_MEMBER_VIEW';
const ADMIN_ACCOUNT = 'ADMIN_ACCOUNT';
const EDIT_NOTIFICATIONS = 'EDIT_NOTIFICATIONS';
const ADMIN_EMAIL_TRACKING = 'ADMIN_EMAIL_TRACKING';
const ADMIN_COURSES_CREATE = 'ADMIN_COURSES_CREATE';
const ADMIN_LESSON_CREATE = 'ADMIN_LESSON_CREATE';
const ADMIN_VIDEO_LESSON_CREATE = 'ADMIN_VIDEO_LESSON_CREATE';
const ADMIN_CATEGORIES = 'ADMIN_CATEGORIES';
const ADMIN_CATEGORIES_CREATE = 'ADMIN_CATEGORIES_CREATE';
const ADMIN_VIDEO_CATEGORIES = 'ADMIN_VIDEO_CATEGORIES';
const ADMIN_PLAYLISTS = 'ADMIN_PLAYLISTS';
const ADMIN_PLAYLIST = 'ADMIN_PLAYLIST';
const ADMIN_VIDEO_CATEGORIES_CREATE = 'ADMIN_VIDEO_CATEGORIES_CREATE';
const ADMIN_VIDEO_CATEGORIES_EDIT = 'ADMIN_VIDEO_CATEGORIES_EDIT';
const ADMIN_COURSE_COMMENTS = 'ADMIN_COURSE_COMMENTS';
const ADMIN_COMMENT_FEED = 'ADMIN_COMMENT_FEED';
const ADMIN_COMMUNITY = 'ADMIN_COMMUNITY';
const ADMIN_COMMUNITY_ROOM_CREATE = 'ADMIN_COMMUNITY_ROOM_CREATE';
const ADMIN_COMMUNITY_INVITE_MEMBER = 'ADMIN_COMMUNITY_INVITE_MEMBER';
const ADMIN_COMMUNITY_ROOM_EVENT = 'ADMIN_COMMUNITY_ROOM_EVENT';
const ADMIN_COMMUNITY_EVENT_SETTINGS = 'ADMIN_COMMUNITY_EVENT_SETTINGS';
const ADMIN_COMMUNITY_ROOM_POST = 'ADMIN_COMMUNITY_ROOM_POST';
const ADMIN_COMMUNITY_SETTINGS = 'ADMIN_COMMUNITY_SETTINGS';
const ADMIN_COMMUNITY_ROOM_SETTINGS = 'ADMIN_COMMUNITY_ROOM_SETTINGS';
const ADMIN_COMMUNITY_MEMBER_PROFILE = 'ADMIN_COMMUNITY_MEMBER_PROFILE';
const ADMIN_COMMUNITY_ROOM_MEMBERS = 'ADMIN_COMMUNITY_ROOM_MEMBERS';
const ADMIN_COMMUNITY_MEMBERS = 'ADMIN_COMMUNITY_MEMBERS';
const ADMIN_COMMUNITY_HOME = 'ADMIN_COMMUNITY_HOME';
const ADMIN_COMMUNITY_MESSENGAR = 'ADMIN_COMMUNITY_MESSENGAR';
const ADMIN_COMMUNITY_PRICING = 'ADMIN_COMMUNITY_PRICING';
const ADMIN_COMMUNITY_PRICING_CREATE = 'ADMIN_COMMUNITY_PRICING_CREATE';
const ADMIN_QUIZZES = 'ADMIN_QUIZZES';
const ADMIN_CREATE_QUIZ = 'ADMIN_CREATE_QUIZ';
const ADMIN_EDIT_QUIZ = 'ADMIN_EDIT_QUIZ';
const ADMIN_CREATE_PLAN = 'ADMIN_CREATE_PLAN';
const ADMIN_PLAN_EDIT = 'ADMIN_PLAN_EDIT';
const ADMIN_ORDER_BUMP_CREATE = 'ADMIN_ORDER_BUMP_CREATE';
const ADMIN_ORDER_BUMP = 'ADMIN_ORDER_BUMP';
const ADMIN_COUPONS = 'ADMIN_COUPONS';
const ADMIN_COUPONS_CREATE = 'ADMIN_COUPONS_CREATE';
const ADMIN_COUPON_VIEW = 'ADMIN_COUPON_VIEW';
const ADMIN_UPSELL_CREATE = 'ADMIN_UPSELL_CREATE';
const ADMIN_DOWNSELL_CREATE = 'ADMIN_DOWNSELL_CREATE';
const ADMIN_QUIZ = 'ADMIN_QUIZ';
const ADMIN_OFFER_CHECKOUT = 'ADMIN_OFFER_CHECKOUT';
const ADMIN_EMAILS_CREATE = 'ADMIN_EMAILS_CREATE';
const ADMIN_UPSELL_EDIT = 'ADMIN_UPSELL_EDIT';
const ADMIN_UPSELL_PREVIEW = 'ADMIN_UPSELL_PREVIEW';
const ADMIN_AFFILIATE = 'ADMIN_AFFILIATE';
const ADMIN_AFFILIATE_CREATE = 'ADMIN_AFFILIATE_CREATE';
const ADMIN_DOWNSELL_PREVIEW = 'ADMIN_DOWNSELL_PREVIEW';
const ADMIN_EMAILS_EDIT = 'ADMIN_EMAILS_EDIT';
const ADMIN_DOWNSELL_EDIT = 'ADMIN_DOWNSELL_EDIT';
const ADMIN_AFFILIATE_USER = 'ADMIN_AFFILIATE_USER';
const ADMIN_AFFILIATE_INVITE_USERS = 'ADMIN_AFFILIATE_INVITE_USERS';
const ADMIN_AFFILIATE_SETTINGS = 'ADMIN_AFFILIATE_SETTINGS';
const ADMIN_AFFILIATE_EDIT = 'ADMIN_AFFILIATE_EDIT';
const ADMIN_AFFILIATE_TEMPLATE_EDIT = 'ADMIN_AFFILIATE_TEMPLATE_EDIT';
const ADMIN_AFFILIATE_TEMPLATE_PREVIEW = 'ADMIN_AFFILIATE_TEMPLATE_PREVIEW';
const BLOCKED_IP = 'BLOCKED_IP';
const OFFERS = 'OFFERS';
const STUDENTS_ROOM_MY_ACCOUNT = 'STUDENTS_ROOM_MY_ACCOUNT';
const ADMIN_AUTOMATION_CREATE_PAGE = 'ADMIN_AUTOMATION_CREATE_PAGE';
const ADMIN_QUIZ_RESULT = 'ADMIN_QUIZ_RESULT';
const FRONT_COMMUNITY = 'FRONT_COMMUNITY';
const ADMIN_OTHER_PAGES = 'ADMIN_OTHER_PAGES';
const ADMIN_OTHER_PAGE = 'ADMIN_OTHER_PAGE';
const ADMIN_OTHER_PAGE_EDIT = 'ADMIN_OTHER_PAGE_EDIT';
const ADMIN_OTHER_PAGE_PREVIEW = 'ADMIN_OTHER_PAGE_PREVIEW';
const UNSUBSCRIBE_EMAIL = 'UNSUBSCRIBE_EMAIL';
const ADMIN_COURSES_PLAN_EDIT = 'ADMIN_COURSES_PLAN_EDIT';
const ADMIN_COURSES_PLAN_CREATE = 'ADMIN_COURSES_PLAN_CREATE';
const ADMIN_MEMBERSHIP_EDIT = 'ADMIN_MEMBERSHIP_EDIT';
const ADMIN_MEMBERSHIP_CREATE = 'ADMIN_MEMBERSHIP_CREATE';
const MEMBERSHIP_CATEGORY = 'MEMBERSHIP_CATEGORY';
const MEMBERSHIP_PLAYLIST = 'MEMBERSHIP_PLAYLIST';

// AFFILIATE FRONT SIDE
const AFFILIATE_FRONT_PASSWORD_RESET = 'AFFILIATE_FRONT_PASSWORD_RESET';
const AFFILIATE_FRONT_LOGIN = 'AFFILIATE_FRONT_LOGIN';
const AFFILIATE_DASHBOARD = 'AFFILIATE_DASHBOARD';
const AFFILIATE_FRONT_PRODUCTS = 'AFFILIATE_FRONT_PRODUCTS';
const AFFILIATE_FRONT_REGISTER = 'AFFILIATE_FRONT_REGISTER';
const AFFILIATE_FRONT_PROFILE_SETTINGS = 'AFFILIATE_FRONT_PROFILE_SETTINGS';
const AFFILIATE_FRONT_REPORTS = 'AFFILIATE_FRONT_REPORTS';

const COMMUNITY_PREFIX = window.location.href.includes('portal') ? 'portal' : 'admin';
const TEST_MESSENGER = 'TEST_MESSENGER';

const routes = {
   [TEST_MESSENGER]: {
      path: '/admin/test-messenger',
      exact: true,
   },
   [AUTH_ATTEMPT]: {
      path: '/auth/attempt',
      exact: true,
   },
   [LOGIN]: {
      path: '/login',
      exact: true,
   },

   [FORGOT_PASSWORD]: {
      path: '/password/reset',
      exact: true,
   },
   [RESET_PASSWORD]: {
      path: '/password/reset/:token',
      exact: true,
   },
   [SIGNUP_STUDENT]: {
      path: '/register',
      exact: true,
   },
   [ADMIN_DASHBOARD]: {
      path: '/admin',
      exact: true,
   },
   [ADMIN_COURSES]: {
      path: '/admin/programs',
      exact: true,
   },
   [ADMIN_COURSES_CREATE]: {
      path: '/admin/programs/create',
      exact: true,
   },
   [ADMIN_SUSPENDED]: {
      path: '/admin/suspended',
      exact: true,
   },
   [ADMIN_COURSES_EDIT]: {
      path: '/admin/programs/:id/edit',
      exact: true,
   },
   [ADMIN_EMAILS]: {
      path: '/admin/emails',
      exact: true,
   },
   // [ADMIN_TEMPLATES]: {
   //    path: `/admin/notifications`,
   //    exact: true,
   // },
   [ADMIN_HELP]: {
      path: '/admin/help',
      exact: true,
   },
   [ADMIN_MEMBERS]: {
      path: '/admin/members',
      exact: true,
   },
   [ADMIN_REPORTS]: {
      path: '/admin/reports',
      exact: true,
   },
   [ADMIN_PLANS]: {
      path: '/admin/bundles',
      exact: true,
   },
   [ADMIN_AFFILATE_PROGRAM]: {
      path: '/admin/affilate-program',
      exact: true,
   },
   [ADMIN_AFFILATES]: {
      path: '/admin/affilates',
      exact: true,
   },
   // [ADMIN_GAMIFICATION]: {
   //    path: `/admin/gamification`,
   //    exact: true,
   // },
   [ADMIN_SITE]: {
      path: '/admin/site',
      exact: true,
   },
   [ADMIN_SETTINGS]: {
      path: '/admin/settings',
      exact: true,
   },

   [ADMIN_SETTINGS_VIDEO]: {
      path: '/admin/video/:id/edit',
      exact: true,
   },

   [MEMBER_ACCOUNT]: {
      path: '/my-account',
      exact: true,
   },
   [COURSES]: {
      path: '/portal/:type',
      exact: true,
   },
   [OFFERS]: {
      path: '/portal/:type',
      exact: true,
   },
   [MEMBERSHIP_CATEGORY]: {
      path: '/portal/membership/:link',
      exact: true,
   },
   [STUDENTS_ROOM_MY_ACCOUNT]: {
      path: '/portal/my-account',
      exact: true,
   },
   [STUDENTS_ROOM]: {
      path: '/programs/:id',
      exact: true,
   },
   [STUDENTS_VIDEOROOM]: {
      path: '/programs/:id/:link',
      exact: true,
   },
   [STUDENTS_PLAYLISTROOM]: {
      path: '/programs/:id/playlists/:playlistLink',
      exact: true,
   },
   [MEMBER_SECURE_COURSE_LOGIN]: {
      path: '/portal/:offer_name/secure-login/:token',
      exact: true,
   },
   [MEMBER_SECURE_LOGIN]: {
      path: '/secure-login/:token/:redirect',
      exact: true,
   },
   [MEMBER_SECURE_LOGIN_THANK_YOU]: {
      path: '/portal/:offer_name/secure-login/:token/:redirect',
      exact: true,
   },
   [THANK_YOU_PREVIEW]: {
      path: '/admin/thank-you/preview',
      exact: true,
   },
   [CHECKOUT]: {
      path: '/checkout/:id/pricing/:pricingId/:enrollMethod',
      exact: true,
   },
   [ADMIN_REVENUE_REPORTS]: {
      path: '/admin/reports/revenue',
      exact: true,
   },
   [ADMIN_REFOUNDS]: {
      path: '/admin/refunds',
      exact: true,
   },
   [ADMIN_TRANSACTIONS]: {
      path: '/admin/transactions',
      exact: true,
   },
   [ADMIN_REPORTS_DASHBOARD]: {
      path: '/admin/dashboard',
   },
   [ADMIN_NET_REVENUE]: {
      path: '/admin/netRevenue',
   },
   [ADMIN_SUBSCRIPTION_METRICS]: {
      path: '/admin/subscriptionMetrics',
   },
   [ADMIN_PAGE_VIEWS]: {
      path: '/admin/reports/view',
   },
   [ADMIN_VIDEOLIBRARY]: {
      path: '/admin/videolibrary',
   },
   [MEMBER_UNSUBSCRIBE_EMAIL]: {
      path: '/unsubscribeold/:uuid/:member_id',
      exact: true,
   },
   [MEDIA_LIBRARY]: {
      path: '/admin/medialibrary',
      exact: true,
   },
   [ADMIN_AUTOMATIONS]: {
      path: '/admin/automations',
      exact: true,
   },
   [ADMIN_AUTOMATION_EDIT]: {
      path: '/admin/automations/:id/edit',
      exact: true,
   },
   [ADMIN_APP_BUILDER]: {
      path: '/admin/app-builder',
      exact: true,
   },
   [ADMIN_BLOG]: {
      path: '/admin/blog',
      exact: true,
   },
   [ADMIN_BLOG_EDIT]: {
      path: '/admin/blog/:id/edit',
      exact: true,
   },
   [BLOG_PREVIEW]: {
      path: '/blog/:id',
      exact: true,
   },
   [BLOG_LISTING]: {
      path: '/blog',
      exact: true,
   },
   [ADMIN_CERTIFICATES]: {
      path: '/admin/certificates',
      exact: true,
   },
   [ADMIN_CREATE_CERTIFICATE]: {
      path: '/admin/createcertificates',
      exact: true,
   },
   [ADMIN_EDIT_CERTIFICATE]: {
      path: '/admin/certificates/:id/edit',
      exact: true,
   },
   [ADMIN_SETTINGS_CERTIFICATE]: {
      path: '/admin/certificates/:id/settings',
      exact: true,
   },
   [ADMIN_REORDER_COURSES]: {
      path: '/admin/reorder-courses',
      exact: true,
   },
   [THANK_YOU]: {
      path: '/thank-you/:course_name',
   },
   [ADMIN_LANDING_PAGES]: {
      path: '/admin/landings',
      exact: true,
   },
   [ADMIN_LANDING_PAGES_CREATE]: {
      path: '/admin/landings/create',
      exact: true,
   },
   [ADMIN_LANDING_PAGES_EDIT]: {
      path: '/admin/landings/:url/edit',
      exact: true,
   },
   [ADMIN_LANDING_PAGES_DETAILS]: {
      path: '/admin/landings/:url/settings',
      exact: true,
   },
   [ADMIN_LIVE_STREAM]: {
      path: '/admin/live-stream',
      exact: true,
   },
   [LANDING_PAGES_PREVIEW]: {
      path: '/template/:id/preview',
      exact: true,
   },

   [ADMIN_COURSES_EDIT_CHECKOUT]: {
      path: '/admin/courses/:id/edit#checkout/:templateId',
      exact: true,
   },
   [CHECKOUT_PREVIEW]: {
      path: '/checkout/:templateType/:courseId/:templateId',
      exact: true,
   },
   [CHECKOUT_TEMP_PREVIEW]: {
      path: '/temp-checkout/:templateType/:courseId/:templateId',
   },
   [ADMIN_SCHOOL_ROOM]: {
      path: '/admin/portal',
      exact: true,
   },
   [ADMIN_SCHOOL_ROOM_CUSTOMIZE]: {
      path: '/admin/portal/customize/:templateId',
      exact: true,
   },
   [ADMIN_SCHOOL_ROOM_EDIT]: {
      path: '/admin/portal/:landingType/:landingId',
      exact: true,
   },
   [ADMIN_SCHOOL_ROOM_SETTINGS]: {
      path: '/admin/portal/settings/:landingType/:landingId',
      exact: true,
   },
   [SCHOOLROOM_PREVIEW]: {
      path: '/portal-preview/:landingType',
      exact: true,
   },
   [SCHOOLROOM_TEMP_PREVIEW]: {
      path: '/temp-portal/:templateId',
   },
   [OTHER_PAGES_PREVIEW]: {
      path: '/other-pages/:pageId/:templateName',
   },
   [ADMIN_VIDEO_METRICS]: {
      path: '/admin/video/metrics',
   },
   [ADMIN_CLASS_PROGRESS]: {
      path: '/admin/class/progress',
   },
   [ADMIN_CLASS__PROGRESS_VIEW]: {
      path: '/admin/class/progress/:id',
   },
   [ADMIN_MEMBER_VIEW]: {
      path: '/admin/members/member/:id',
   },
   [ADMIN_ACCOUNT]: {
      path: '/admin/account',
   },
   [EDIT_NOTIFICATIONS]: {
      path: '/admin/settings/notification/:id/edit',
   },
   [ADMIN_EMAIL_TRACKING]: {
      path: '/admin/settings/emails/tracking',
   },
   [ADMIN_LESSON_CREATE]: {
      path: '/admin/programs/:id/sections/:sectionId/lessons/:lessonId',
   },
   [ADMIN_VIDEO_LESSON_CREATE]: {
      path: '/admin/programs/video/:id/sections/:sectionId/lessons/:lessonId',
   },
   [ADMIN_CATEGORIES]: {
      path: '/admin/programs/categories',
   },
   [ADMIN_CATEGORIES_CREATE]: {
      path: '/admin/programs/categories/create',
   },
   [ADMIN_VIDEO_CATEGORIES]: {
      path: '/admin/video/categories',
   },
   [ADMIN_PLAYLISTS]: {
      path: '/admin/programs/:id/sections/:sectionId/playlists',
   },
   [ADMIN_PLAYLIST]: {
      path: '/admin/programs/:id/sections/:sectionId/playlists/:playlistId',
   },
   [ADMIN_VIDEO_CATEGORIES_CREATE]: {
      path: '/admin/video/categories/create',
   },
   [ADMIN_VIDEO_CATEGORIES_EDIT]: {
      path: '/admin/video/categories/:id/edit',
   },
   [ADMIN_COURSE_COMMENTS]: {
      path: '/admin/programs/:id/comments',
   },
   [ADMIN_COMMENT_FEED]: {
      path: '/admin/program/:courseId/comment/:id',
   },
   [ADMIN_COMMUNITY]: {
      path: `/${ COMMUNITY_PREFIX }/community/:id`,
      exact: true,
   },
   [ADMIN_COMMUNITY_MEMBERS]: {
      path: `/${ COMMUNITY_PREFIX }/community/:id/members`,
      exact: true,
   },
   [ADMIN_COMMUNITY_HOME]: {
      path: `/${ COMMUNITY_PREFIX }/community/:id/home`,
      exact: true,
   },
   [ADMIN_COMMUNITY_ROOM_CREATE]: {
      path: `/${ COMMUNITY_PREFIX }/community/:id/room/create`,
      exact: true,
   },
   [ADMIN_COMMUNITY_INVITE_MEMBER]: {
      path: `/${ COMMUNITY_PREFIX }/community/:id/invite`,
   },
   [ADMIN_COMMUNITY_ROOM_EVENT]: {
      path: `/${ COMMUNITY_PREFIX }/community/:id/room/:roomId/event`,
   },
   [ADMIN_COMMUNITY_EVENT_SETTINGS]: {
      path: `/${ COMMUNITY_PREFIX }/community/:id/room/:roomId/group/:groupId/event/:eventId`,
   },
   [ADMIN_COMMUNITY_ROOM_POST]: {
      path: `/${ COMMUNITY_PREFIX }/community/:id/room/:roomId/post`,
   },
   [ADMIN_COMMUNITY_SETTINGS]: {
      path: `/${ COMMUNITY_PREFIX }/community/:id/settings`,
   },
   [ADMIN_COMMUNITY_MESSENGAR]: {
      path: `/${ COMMUNITY_PREFIX }/community/:id/messenger`,
   },
   [ADMIN_COMMUNITY_ROOM_SETTINGS]: {
      path: `/${ COMMUNITY_PREFIX }/community/:id/room/:roomId/settings`,
   },
   [ADMIN_COMMUNITY_MEMBER_PROFILE]: {
      path: `/${ COMMUNITY_PREFIX }/community/:id/member/:memberId`,
   },
   [ADMIN_COMMUNITY_ROOM_MEMBERS]: {
      path: `/${ COMMUNITY_PREFIX }/community/:id/group/:groupId/room/:roomId-:type/members`,
   },
   [ADMIN_COMMUNITY_PRICING]: {
      path: '/admin/community/:id/pricing/:planId',
   },
   [ADMIN_COMMUNITY_PRICING_CREATE]: {
      path: '/admin/community/:id/pricing/:courseId/create',
   },
   [ADMIN_QUIZZES]: {
      path: '/admin/quizzes',
   },
   [ADMIN_CREATE_QUIZ]: {
      path: '/admin/quizzes/create',
   },
   [ADMIN_EDIT_QUIZ]: {
      path: '/admin/quizzes/edit/:id',
   },
   [ADMIN_CREATE_PLAN]: {
      path: '/admin/bundles/create',
   },
   [ADMIN_PLAN_EDIT]: {
      path: '/admin/bundles/:id/edit',
   },
   [ADMIN_ORDER_BUMP_CREATE]: {
      path: '/admin/bundles/:id/order',
   },
   [ADMIN_ORDER_BUMP]: {
      path: '/admin/bundle/:planId/order/:id',
   },
   [ADMIN_COUPONS]: {
      path: '/admin/coupons',
   },
   [ADMIN_COUPONS_CREATE]: {
      path: '/admin/coupons/create',
   },
   [ADMIN_COUPON_VIEW]: {
      path: '/admin/coupon/:id',
   },
   [ADMIN_UPSELL_CREATE]: {
      path: '/admin/upsell/:offerId/create',
   },
   [ADMIN_UPSELL_EDIT]: {
      path: '/admin/upsell/:upsellId/edit/:offerId',
   },
   [ADMIN_DOWNSELL_EDIT]: {
      path: '/admin/downsell/:downsellId/edit/:offerId',
   },
   [ADMIN_UPSELL_PREVIEW]: {
      path: '/admin/upsell/:upsellId/preview',
   },
   [ADMIN_DOWNSELL_PREVIEW]: {
      path: '/admin/downsell/:downsellId/preview',
   },
   [ADMIN_DOWNSELL_CREATE]: {
      path: '/admin/upsell/:id/downsell/:offerId/create/:upsellOfferId',
   },
   [ADMIN_QUIZ]: {
      path: '/admin/quiz/:id',
   },
   [ADMIN_OFFER_CHECKOUT]: {
      path: '/admin/bundle/:offerId/checkout/:id/:name',
   },
   [ADMIN_EMAILS_CREATE]: {
      path: '/admin/emails/create',
   },
   // Affiliate
   [ADMIN_AFFILIATE]: {
      path: '/admin/affiliate',
   },
   [ADMIN_AFFILIATE_CREATE]: {
      path: '/admin/affiliate/create',
   },
   [ADMIN_AFFILIATE_USER]: {
      path: '/admin/affiliate/:affiliateId/user/:id',
   },
   [ADMIN_AFFILIATE_INVITE_USERS]: {
      path: '/admin/affiliate/users/invite',
   },
   [ADMIN_AFFILIATE_SETTINGS]: {
      path: '/admin/affiliate/:id/settings',
   },
   [ADMIN_AFFILIATE_EDIT]: {
      path: '/admin/affiliate/:id/edit',
   },
   [ADMIN_AFFILIATE_TEMPLATE_EDIT]: {
      path: '/admin/affiliate/:id/template/:templateId',
   },
   [ADMIN_AFFILIATE_TEMPLATE_PREVIEW]: {
      path: '/admin/affiliate/:id/template/preview/:templateId',
   },
   // End Affiliate
   [ADMIN_EMAILS_EDIT]: {
      path: '/admin/emails/:id/edit',
   },
   [BLOCKED_IP]: {
      path: '/vpn',
   },
   [ADMIN_AUTOMATION_CREATE_PAGE]: {
      path: '/admin/automation/create',
   },
   [ADMIN_QUIZ_RESULT]: {
      path: '/admin/quiz/result/:id/:resultId',
   },
   [FRONT_COMMUNITY]: {
      path: '/portal/community/:id',
      exact: true,
   },
   [ADMIN_OTHER_PAGES]: {
      path: '/admin/other-pages',
   },
   [ADMIN_OTHER_PAGE]: {
      path: '/admin/other-pages/:type',
   },
   [ADMIN_OTHER_PAGE_EDIT]: {
      path: '/admin/other-page/edit/:id',
   },
   [ADMIN_OTHER_PAGE_PREVIEW]: {
      path: '/admin/other-pages/preview/:type',
   },
   [AFFILIATE_DASHBOARD]: {
      path: '/affiliate/dashboard',
      exact: true,
   },
   [AFFILIATE_FRONT_PASSWORD_RESET]: {
      path: '/affiliate/password/reset',
   },
   [AFFILIATE_FRONT_LOGIN]: {
      path: '/affiliate/login',
   },
   [AFFILIATE_FRONT_REGISTER]: {
      path: '/affiliate/register',
   },
   [AFFILIATE_FRONT_PRODUCTS]: {
      path: '/affiliate/products',
   },
   [AFFILIATE_FRONT_PROFILE_SETTINGS]: {
      path: '/affiliate/profile',
   },
   [AFFILIATE_FRONT_REPORTS]: {
      path: '/affiliate/reports',
   },
   [UNSUBSCRIBE_EMAIL]: {
      path: '/unsubscribe/:site_uiid/:email',
   },
   [ADMIN_COURSES_PLAN_EDIT]: {
      path: '/admin/programs/:id/edit/pricing',
   },
   [ADMIN_COURSES_PLAN_CREATE]: {
      path: '/admin/programs/:id/create/pricing',
   },
   [ADMIN_MEMBERSHIP_EDIT]: {
      path: '/admin/membership/:id/edit',
   },
   [ADMIN_MEMBERSHIP_CREATE]: {
      path: '/admin/membership/create',
   },
   [MEMBERSHIP_PLAYLIST]: {
      path: '/portal/membership/playlists/:playlistLink',
      exact: true,
   },
};
export default routes;
