export const RELOAD_REDUX_STATE = 'courses/edit/RELOAD_REDUX_STATE';
export const SET_INPUT = 'courses/edit/SET_INPUT';
export const SET_RESOURCE_INPUT = 'courses/edit/SET_RESOURCE_INPUT';
export const SET_SETTINGS_INPUT_START = 'courses/edit/SET_SETTINGS_INPUT_START';
export const SET_SETTINGS_INPUT = 'courses/edit/SET_SETTINGS_INPUT';
export const CHOOSE_SECTION = 'courses/edit/CHOOSE_SECTION';

export const CREATE_SECTION_START = 'courses/edit/CREATE_SECTION_START';
export const CREATE_SECTION_COMPLETED = 'courses/edit/CREATE_SECTION_COMPLETED';
export const CREATE_SECTION_FAILED = 'courses/edit/CREATE_SECTION_FAILED';

export const EDIT_SECTION_START = 'courses/edit/EDIT_SECTION_START';
export const EDIT_SECTION_COMPLETED = 'courses/edit/EDIT_SECTION_COMPLETED';
export const EDIT_SECTION_FAILED = 'courses/edit/EDIT_SECTION_FAILED';

export const DELETE_SECTION_START = 'courses/edit/DELETE_SECTION_START';
export const DELETE_SECTION_COMPLETED = 'courses/edit/DELETE_SECTION_COMPLETED';
export const DELETE_SECTION_FAILED = 'courses/edit/DELETE_SECTION_FAILED';

export const UPDATE_COURSE_START = 'courses/edit/UPDATE_COURSE_START';
export const UPDATE_COURSE_COMPLETED = 'courses/edit/UPDATE_COURSE_COMPLETED';
export const UPDATE_COURSE_FAILED = 'courses/edit/UPDATE_COURSE_FAILED';

export const GET_COURSE_START = 'courses/edit/GET_COURSE_START';
export const GET_COURSE_COMPLETED = 'courses/edit/GET_COURSE_COMPLETED';
export const GET_COURSE_FAILED = 'courses/edit/GET_COURSE_FAILED';

export const GET_LESSON_START = 'courses/edit/GET_LESSON_START';
export const GET_LESSON_COMPLETED = 'courses/edit/GET_LESSON_COMPLETED';
export const GET_LESSON_FAILED = 'courses/edit/GET_LESSON_FAILED';

export const CREATE_LESSON_START = 'courses/edit/CREATE_LESSON_START';
export const CREATE_LESSON_COMPLETED = 'courses/edit/CREATE_LESSON_COMPLETED';
export const CREATE_LESSON_FAILED = 'courses/edit/CREATE_LESSON_FAILED';
export const CLEAR_LESSON_DATA = 'courses/edit/CLEAR_LESSON_DATA';

export const SAVE_LESSON_START = 'courses/edit/SAVE_LESSON_START';
export const SAVE_LESSON_COMPLETED = 'courses/edit/SAVE_LESSON_COMPLETED';
export const SAVE_LESSON_FAILED = 'courses/edit/SAVE_LESSON_FAILED';

export const CREATE_QUESTION_START = 'courses/edit/CREATE_QUESTION_START';
export const CREATE_QUESTION_COMPLETED = 'courses/edit/CREATE_QUESTION_COMPLETED';
export const CREATE_QUESTION_FAILED = 'courses/edit/CREATE_QUESTION_FAILED';

export const UPDATE_QUESTION_START = 'courses/edit/UPDATE_QUESTION_START';
export const UPDATE_QUESTION_COMPLETED = 'courses/edit/UPDATE_QUESTION_COMPLETED';
export const UPDATE_QUESTION_FAILED = 'courses/edit/UPDATE_QUESTION_FAILED';

export const DELETE_QUESTION_START = 'courses/edit/DELETE_QUESTION_START';
export const DELETE_QUESTION_COMPLETED = 'courses/edit/DELETE_QUESTION_COMPLETED';
export const DELETE_QUESTION_FAILED = 'courses/edit/DELETE_QUESTION_FAILED';

export const DELETE_LESSON_START = 'courses/edit/DELETE_LESSON_START';
export const DELETE_LESSON_COMPLETED = 'courses/edit/DELETE_LESSON_COMPLETED';
export const DELETE_LESSON_FAILED = 'courses/edit/DELETE_LESSON_FAILED';

export const SET_CURRENT_PRICING = 'courses/edit/SET_CURRENT_PRICING';
export const ADD_PLAN = 'courses/edit/ADD_PLAN';
export const ADD_PLAN_IN_CREATE = 'courses/edit/ADD_PLAN_IN_CREATE';
export const SET_PLAN_INPUT = 'courses/edit/SET_PLAN_INPUT';


export const CREATE_PLAN_START = 'courses/edit/CREATE_PLAN_START';
export const CREATE_PLAN_COMPLETED = 'courses/edit/CREATE_PLAN_COMPLETED';
export const CREATE_PLAN_FAILED = 'courses/edit/CREATE_PLAN_FAILED';

export const UPDATE_PLAN_START = 'courses/edit/UPDATE_PLAN_START';
export const UPDATE_PLAN_COMPLETED = 'courses/edit/UPDATE_PLAN_COMPLETED';
export const UPDATE_PLAN_FAILED = 'courses/edit/UPDATE_PLAN_FAILED';

export const DELETE_PLAN_START = 'courses/edit/DELETE_PLAN_START';
export const DELETE_PLAN_COMPLETED = 'courses/edit/DELETE_PLAN_COMPLETED';
export const DELETE_PLAN_FAILED = 'courses/edit/DELETE_PLAN_FAILED';

export const SET_COUPON_INPUT = 'courses/edit/SET_COUPON_INPUT';

export const CREATE_COUPON_START = 'courses/edit/CREATE_COUPON_START';
export const CREATE_COUPON_COMPLETED = 'courses/edit/CREATE_COUPON_COMPLETED';
export const CREATE_COUPON_FAILED = 'courses/edit/CREATE_PLAN_FAILED';

export const SHOW_COUPON_POPUP = 'courses/edit/SHOW_COUPON_POPUP';

export const DELETE_COUPON_START = 'courses/edit/DELETE_COUPON_START';
export const DELETE_COUPON_COMPLETED = 'courses/edit/DELETE_COUPON_COMPLETED';
export const DELETE_COUPON_FAILED = 'courses/edit/DELETE_COUPON_FAILED';

export const UPDATE_SIGN_UP_START = 'courses/edit/UPDATE_SIGN_UP_START';
export const UPDATE_SIGN_UP_COMPLETED = 'courses/edit/UPDATE_SIGN_UP_COMPLETED';
export const UPDATE_SIGN_UP_FAILED = 'courses/edit/UPDATE_SIGN_UP_FAILED';

export const SET_SIGN_UP_INPUT = 'course/edit/SET_SIGN_UP_INPUT';

export const DELETE_SIGN_UP_START = 'courses/edit/DELETE_SIGN_UP_START';
export const DELETE_SIGN_UP_COMPLETED = 'courses/edit/DELETE_SIGN_UP_COMPLETED';
export const DELETE_SIGN_UP_FAILED = 'courses/edit/DELETE_SIGN_UP_FAILED';
export const GET_AUTORESPONDERS_LISTS_COMPLETED = 'courses/edit/GET_AUTORESPONDERS_LISTS_COMPLETED';
export const GET_COURSE_CHECKOUT_COMPLETED = 'courses/edit/GET_COURSE_CHECKOUT_COMPLETED';


export const HIDE_COURSE_START = 'courses/edit/HIDE_COURSE_START';
export const HIDE_COURSE_COMPLETED = 'courses/edit/HIDE_COURSE_COMPLETED';
export const HIDE_COURSE_FAILED = 'courses/edit/HIDE_COURSE_FAILED';

export const TOGGLE_COMMENT_SHOW_START = 'studentsroom/TOGGLE_COMMENT_SHOW_START';
export const TOGGLE_COMMENT_SHOW_COMPLETED = 'studentsroom/TOGGLE_COMMENT_SHOW_COMPLETED';
export const TOGGLE_COMMENT_SHOW_FAILED = 'studentsroom/TOGGLE_COMMENT_SHOW_FAILED';


export const UPDATE_COURSELINK_START = 'courses/edit/UPDATE_COURSELINK_START';
export const UPDATE_COURSELINK_COMPLETED = 'courses/edit/UPDATE_COURSELINK_COMPLETED';
export const UPDATE_COURSELINK_FAILED = 'courses/edit/UPDATE_COURSELINK_FAILED';


export const SAVE_LESSONRESOURCE_START = 'courses/edit/SAVE_LESSONRESOURCE_START';
export const SAVE_LESSONRESOURCE_COMPLETED = 'courses/edit/SAVE_LESSONRESOURCE_COMPLETED';
export const SAVE_LESSONRESOURCE_FAILED = 'courses/edit/SAVE_LLESSONRESOURCE_FAILED';


export const DELETE_LESSONRESOURCE_START = 'courses/edit/DELETE_LESSONRESOURCE_START';
export const DELETE_LESSONRESOURCE_COMPLETED = 'courses/edit/DELETE_LESSONRESOURCE_COMPLETED';
export const DELETE_LESSONRESOURCE_FAILED = 'courses/edit/DELETE_LESSONRESOURCE_FAILED';

export const DELETEALL_LESSONRESOURCES_START = 'courses/edit/DELETEALL_LESSONRESOURCES_START';
export const DELETEALL_LESSONRESOURCES_COMPLETED = 'courses/edit/DELETEALL_LESSONRESOURCES_COMPLETED';
export const DELETEALL_LESSONRESOURCES_FAILED = 'courses/edit/DELETEALL_LESSONRESOURCES_FAILED';

export const EDIT_LESSONRESOURCE_START = 'courses/edit/EDIT_LESSONRESOURCE_START';
export const EDIT_LESSONRESOURCE_COMPLETED = 'courses/edit/EDIT_LESSONRESOURCE_COMPLETED';
export const EDIT_LESSONRESOURCE_FAILED = 'courses/edit/EDIT_LESSONRESOURCE_FAILED';

export const CHOOSE_RESOURCE = 'courses/edit/CHOOSE_RESOURCE';


export const SAVE_LESSONSETTINGS_START = 'courses/edit/SAVE_LESSONSETTINGS_START';
export const SAVE_LESSONSETTINGS_COMPLETED = 'courses/edit/SAVE_LESSONSETTINGS_COMPLETED';
export const SAVE_LESSONSETTINGS_FAILED = 'courses/edit/SAVE_LESSONSETTINGS_FAILED';


export const LESSON_ORDER_COMPLETED = 'courses/edit/LESSON_ORDER_COMPLETED';
export const LESSON_ORDER_START = 'courses/edit/LESSON_ORDER_START';
export const LESSON_ORDER_FAILED = 'courses/edit/LESSON_ORDER_FAILED';

export const SECTION_ORDER_COMPLETED = 'courses/edit/SECTION_ORDER_COMPLETED';
export const SECTION_ORDER_START = 'courses/edit/SECTION_ORDER_START';
export const SECTION_ORDER_FAILED = 'courses/edit/SECTION_ORDER_FAILED';

export const UPDATE_SETTINGS_COMPLETED = 'courses/edit/UPDATE_SETTINGS_COMPLETED';
export const HIDE_COURSE_LIVEINTEGRATION_MODAL = 'courses/edit/HIDE_COURSE_LIVEINTEGRATION_MODAL';

export const CHOOSE_TESTIMONIAL = 'courses/edit/CHOOSE_TESTIMONIAL';
export const CHOOSE_BULLET = 'courses/edit/CHOOSE_BULLET';
export const UPDATE_COURSE_THEME = 'courses/edit/UPDATE_COURSE_THEME';
export const ADD_COURSE_TAG = 'courses/edit/ADD_COURSE_TAG';
export const ATACH_COURSE_TAG = 'courses/edit/ATACH_COURSE_TAG';
export const DETACH_COURSE_TAG = 'courses/edit/DETACH_COURSE_TAG';

export const ADD_TESTIMONIAL = 'courses/edit/ADD_TESTIMONIAL';
export const ADD_BULLET = 'courses/edit/ADD_BULLET';
export const ADD_QUESTION_ACTION = 'courses/edit/ADD_QUESTION_ACTION';

export const CHOOSE_COURSES_OPTIONS = 'courses/edit/CHOOSE_COURSES_OPTIONS';
export const ADD_FILTER_OPTION = 'courses/edit/ADD_FILTER_OPTION';
export const REMOVE_FILTER_OPTION = 'courses/edit/REMOVE_FILTER_OPTION';
export const SAVE_LESSONTITLE_START = 'courses/edit/SAVE_LESSONTITLE_START';
export const SAVE_LESSONTITLE_COMPLETED = 'courses/edit/SAVE_LESSONTITLE_COMPLETED';
export const SAVE_LESSONTITLE_FAILED = 'courses/edit/SAVE_LESSONTITLE_FAILED';
export const GET_LANDING_COMPLETED = 'courses/edit/GET_LANDING_COMPLETED';
export const SET_AUTHOR = 'courses/edit/SET_AUTHOR';
export const SET_INSTRUCTOR_INPUT = 'courses/edit/SET_INSTRUCTOR_INPUT';
export const ATACH_CATEGORIES = 'courses/edit/ATACH_CATEGORIES';
export const UPDATE_ATACHED_CATEGORIES = 'courses/edit/UPDATE_ATACHED_CATEGORIES';
export const SET_PLAN_DRAWER_STATE = 'courses/edit/UPDATE_ATACHED_CATEGORIES';
export const UPDATE_COURSE_SETTINGS = 'courses/edit/UPDATE_COURSE_SETTINGS';


export const UPDATE_ZOOM_SETTINGS_START = 'courses/edit/UPDATE_ZOOM_SETTINGS_START';
export const UPDATE_ZOOM_SETTINGS_FINISH = 'courses/edit/UPDATE_ZOOM_SETTINGS_FINISH';
export const UPDATE_ZOOM_SETTINGS_COMPLETE = 'courses/edit/UPDATE_ZOOM_SETTINGS_COMPLETE';


export const CHANGES_SECTION_START = 'course/edit/CHANGES_SECTION_START';
export const CHANGES_SECTION_END = 'course/edit/CHANGES_SECTION_END';
export const CHANGES_SECTION_COMPLETED = 'course/edit/CHANGES_SECTION_COMPLETED';

export const GET_ALL_COURSES_COMPLETED = 'course/edit/GET_ALL_COURSES_COMPLETED';

export const TRANSFER_SECTION_START = 'course/edit/TRANSFER_SECTION_START';
export const TRANSFER_SECTION_FINISHED = 'course/edit/TRANSFER_SECTION_FINISHED';

export const COPY_SECTION_START = 'course/edit/COPY_SECTION_START';
export const COPY_SECTION_FINISHED = 'course/edit/COPY_SECTION_FINISHED';


export const DUPLICATE_BLOCK_START = 'courses/edit/DUPLICATE_BLOCK_START';
export const DUPLICATE_BLOCK_COMPLETED = 'courses/edit/DUPLICATE_BLOCK_COMPLETED';
export const DUPLICATE_BLOCK_FAILED = 'courses/edit/DUPLICATE_BLOCK_FAILED';

export const DELETE_BLOCK_START = 'courses/edit/DELETE_BLOCK_START';
export const DELETE_BLOCK_COMPLETED = 'courses/edit/DELETE_BLOCK_COMPLETED';
export const DELETE_BLOCK_FAILED = 'courses/edit/DELETE_BLOCK_FAILED';

export const FILTER_SEARCH_START = 'course/edit/FILTER_SEARCH_START';
export const FILTER_SEARCH_END = 'course/edit/FILTER_SEARCH_END';

export const UPDATE_CURRENTLESSON = 'course/edit/UPDATE_CURRENTLESSON';

export const PRODUCT_SETTINGS_START = 'course/edit/PRODUCT_SETTINGS_START';
export const PRODUCT_SETTINGS_COMPLETED = 'course/edit/PRODUCT_SETTINGS_COMPLETED';
export const PRODUCT_SETTINGS_FAILED = 'course/edit/PRODUCT_SETTINGS_FAILED';

export const DELETE_QUIZQUESTION_START = 'course/edit/DELETE_QUIZQUESTION_START';
export const DELETE_QUIZQUESTION_COMPLETED = 'course/edit/DELETE_QUIZQUESTION_COMPLETED';
export const DELETE_QUIZQUESTION_FAILED = 'course/edit/DELETE_QUIZQUESTION_FAILED';

export const DELETE_QUIZANSWER_START = 'course/edit/DELETE_QUIZANSWER_START';
export const DELETE_QUIZANSWER_COMPLETED = 'course/edit/DELETE_QUIZANSWER_COMPLETED';
export const DELETE_QUIZANSWER_FAILED = 'course/edit/DELETE_QUIZANSWER_FAILED';

export const CHOOSE_SAVEDTEMPLATE_START = 'course/edit/CHOOSE_SAVEDTEMPLATE_START';
export const CHOOSE_SAVEDTEMPLATE_COMPLETED = 'course/edit/CHOOSE_SAVEDTEMPLATE_COMPLETED';
export const CHOOSE_SAVEDTEMPLATE_FAILED = 'course/edit/CHOOSE_SAVEDTEMPLATE_FAILED';

export const ADD_NEWQUESTION_START = 'course/edit/ADD_NEWQUESTION_START';
export const ADD_NEWQUESTION_COMPLETED = 'course/edit/ADD_NEWQUESTION_COMPLETED';
export const ADD_NEWQUESTION_FAILED = 'course/edit/ADD_NEWQUESTION_FAILED';

export const ADD_NEWANSWER_START = 'course/edit/ADD_NEWANSWER_START';
export const ADD_NEWANSWER_COMPLETED = 'course/edit/ADD_NEWANSWER_COMPLETED';
export const ADD_NEWANSWER_FAILED = 'course/edit/ADD_NEWANSWER_FAILED';

export const LESSON_SELECT_AUTHOR = 'lesson/LESSON_SELECT_AUTHOR';
